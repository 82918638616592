import "../styles/index.scss"

import Fade from "react-reveal/Fade"
import Footer from "./footer"
import React from "react"
import layoutStyle from "../styles/layout.module.scss"

const DefaultLayoutWebkit = props => {
  return (
    <div>
      <Fade>
        <div className={layoutStyle.content}>{props.children}</div>
      </Fade>
      <Footer className={layoutStyle.footer} />
    </div>
  )
}

export default DefaultLayoutWebkit
